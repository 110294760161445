body {
    color: #333;
    font-family: arial;
    max-width: 650px;
    margin: 0 auto;
    padding: 0 16px;
}

#app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer {
    color: #888;
    border-top: 1px solid #eee;
    margin-top: 16px;
    padding: 16px 0;
}

header {
    margin-top: 16px;
    margin-bottom: 48px;
}

h1 {
    font-size: 64px;
    margin-bottom: 16px;
}

header a {
    color: #888;
    margin-right: 16px;
    text-decoration: none;
}

header a:hover {
    color: #555;
}

.portrait {
    width: 250px;
}

.main-content {
    flex-grow: 1;
}

input {
    border: 1px solid #ccc;
    outline: none;
    padding: 8px;
}

button {
    background: #888;
    border: 1px solid #888;
    color: white;
    cursor: pointer;
    outline: none;
    padding: 8px;
}

button:hover {
    background: #555;
}
